import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderBeDigital from "../components/headerBeDigital"
import Boton from "../components/boton"
import "../styles/bedigital.scss"
import "../styles/contacto.scss"
import { IconContext } from "react-icons"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function BeDigital(props) {
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("leadbedigital")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({ nombre, email, telefono, result })
    // POST request to your server

    fetch(`${process.env.API_URL}/leadsbedigitals`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmail("")
          setTelefono("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <Layout>
      <Seo
        title="BeDIGITAL"
        pathname={props.location.pathname}
        imagen="https://bullhost.security/img/cabeceras/cabecera-bedigital-2.jpg"
        robots="noindex,follow"
      />
      <div className="cabecera-bedigital">
        <HeaderBeDigital
          titulo="¿Tiene la industria la potencia suficiente como para resistir un ciberataque?"
          imagen="/img/cabeceras/cabecera-bedigital-2.jpg"
        />
        <div className="intro-bedigital">
          <p>
            Con las máquinas y la producción conectadas a la red, una buena
            estrategia y defensa en ciberseguridad industrial es lo único que
            puede pararlos. Y por este motivo, Bullhost está en BeDIGITAL.
          </p>
        </div>
      </div>
      <div className="cuerpo-servicio">
        <section className="limitador">
          <p className="parrafo-destacado destacado-be">
            Un evento orientado a la transformación digital de la industria de
            Euskadi porque, si algo llevamos en nuestro ADN es a ella, y la
            queremos segura.
          </p>
        </section>
        <section className="bloque-oscuro cta-digital-background">
          <div className="overlay-contacto"></div>
          <div className="header-inicio limitador-inicio">
            <div className="detalle destacado">
              <p>DÓNDE ENCONTRARNOS</p>
            </div>
            <div className="titulo">
              <h2>
                <a
                  href="https://bedigital.bilbaoexhibitioncentre.com/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  BeDIGITAL 2021
                </a>
                en el
                <a
                  href="https://goo.gl/maps/NRhWfTvMacbV79we8"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Bilbao Exhibition Centre - BEC!
                </a>
                Stand J17.
                <br />
                Los días 26, 27 y 28 de octubre.
              </h2>
              <p>
                Puedes reservar ya inscribiéndote con nosotros y acceder de
                forma gratuita a todo el área expositiva a través de este link.
              </p>
              <Boton
                color="secundario"
                url="https://www.portalbec.com/portal/page/portal/PORTALBEC/BEDIGITAL/FINSCR_INV/?clave7=16649601_BullHost_Security"
                texto="Quiero asistir"
                externo={true}
              />
            </div>
          </div>
        </section>
        <section className="limitador contenido__servicio bepad">
          <div className="limitador begrid">
            <div className="betexto">
              <p>
                Durante la totalidad del evento, si te acercas a nuestro stand
                (recuerda, es el J17) podrás ver en directo cómo se ejecuta un
                ataque a PLCs de máquinas industriales y cómo es posible
                detectarlo y detenerlo. Te va a gustar.
              </p>
              <p className="letratitulo">
                ¡Además podrás participar en un sorteo de un iPad sólo con tu
                visita!
              </p>
            </div>
            <div className="centrado-digital betablet">
              <img
                src="../../img/mockup-ipad-hacker.webp"
                alt="¡Sorteamos un iPad!"
              />
            </div>
          </div>
        </section>
        <section className="bloque-oscuro bedigital-charla">
          <div className="limitador contenido__servicio header-inicio">
            <StaticImage
              objectFit="contain"
              src="../../static/img/bedigital-innovation.webp"
              alt="Innovation Workshop"
            />
            <div className="charla-texto">
              <h2>Innovation Workshops</h2>
              <p>
                Durante los días de exposición, en BeDIGITAL, se celebrarán una
                serie de charlas rápidas en las que se podrán ver servicios
                innovadores o casos de éxito de las diferentes compañías
                participantes y Bullhost no podía estar fuera.
              </p>
              <span className="bullet">TEMA</span>
              <p>La necesidad de contar con un SOC en el mundo ICS.</p>
              <span className="bullet">CUÁNDO</span>
              <p>Miércoles 27 de octubre, a las 15:40 horas.</p>
              <span className="bullet">QUIÉN</span>
              <p>Jon Azkarate: Director de Operaciones de Bullhost.</p>
              <span className="bullet">DÓNDE</span>
              <p>Speaker Corner – Pabellón 1.</p>
            </div>
          </div>
        </section>
        <section className="limitador contenido__servicio">
          <h2 className="titulo-be">¿No puedes asistir?</h2>
          <div className="bloque-noasistir">
            <div className="prueba-demo">
              <div className="bloque-noasistir-p">
                <p className="bloque-noasistir-p">
                  Sigue el evento en redes con los hashtag:
                </p>
                <div className="redes-sociales">
                  <p className="hashtag">
                    <a href="https://twitter.com/hashtag/BeDigitalBullhost">
                      #BeDigitalBullhost
                    </a>
                  </p>
                  <p className="hashtag">
                    <a href="https://twitter.com/hashtag/BeDigitalBilbao">
                      #BeDigitalBilbao
                    </a>
                  </p>
                </div>
                <p className="bloque-noasistir-p texto-cta">
                  Si además estás interesado en la demo, pero no vas a poder
                  asistir al evento rellena este formulario y hablamos:
                </p>
              </div>
              <div className="cuerpo-contacto bloque-noasistir-div">
                <div className="caja-formulario">
                  <form
                    className="form-contacto"
                    method="post"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      name="nombre"
                      placeholder="Nombre*"
                      required
                      value={nombre}
                      onChange={e => setNombre(e.target.value)}
                    />

                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <label htmlFor="telefono">Teléfono</label>
                    <input
                      type="tel"
                      name="telefono"
                      placeholder="Teléfono*"
                      maxLength="14"
                      minLength="9"
                      required
                      value={telefono}
                      onChange={e => setTelefono(e.target.value)}
                    />
                    <div className="caja-checkbox">
                      <input
                        type="checkbox"
                        id="privacidad"
                        name="privacidad"
                        // defaultChecked={isChecked}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <label htmlFor="privacidad" className="label-check">
                        He leído y acepto la{" "}
                        <Link to="/politica-de-privacidad/">
                          política de privacidad
                        </Link>
                      </label>
                    </div>

                    <div
                      className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                      dangerouslySetInnerHTML={{ __html: mensajeError }}
                    />

                    <IconContext.Provider value={{ color: "#1f1f1f" }}>
                      <input
                        type="submit"
                        value="Enviar"
                        className="boton-enviar"
                        disabled={!isChecked}
                      />
                    </IconContext.Provider>
                    <p className="texto-recaptcha">
                      Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                      <a href="https://policies.google.com/privacy">
                        política de privacidad
                      </a>{" "}
                      y los{" "}
                      <a href="https://policies.google.com/terms">
                        términos del servicio
                      </a>{" "}
                      de Google.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

import React from 'react'



export default function HeaderBeDigital(props) {


    return (
        <>
            <div className="cabecera-digital" style={{ backgroundImage: `url(${props.imagen})` }}>
                <h1>{props.titulo}</h1>
            </div>
        </>
    );
}